import { RECORD_TYPES } from '@emobg/motion-ui';
import { sentenceCase } from '@emobg/web-utils';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import fleet from '@domains/Carsharing/router/FleetRouterMap';
import { PermissionLink } from '@/components';

export const attributesToRetrieve = [
  'vehicle_uuid',
  'vehicle_license_plate',
  'vehicle_brand',
  'vehicle_model',
  'vehicle_category',
  'vehicle_seats',
  'vehicle_transmission',
];

export const contentCells = [
  {
    title: 'Plate',
    attributeName: 'vehicle_license_plate',
    displayPriority: 1,
    columnRatio: 1,
    minWidth: 80,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
      to: {
        name: fleet.vehicles.detail.details,
        params: {
          vehicleUuid: result.vehicle_uuid,
        },
      },
      text: result.vehicle_license_plate,
    }),
  },
  {
    title: 'Model',
    displayPriority: 1,
    minWidth: 140,
    transformResult: result => `${result.vehicle_brand} ${result.vehicle_model}`,
  },
  {
    attributeName: 'vehicle_category',
    title: 'Category',
    displayPriority: 1,
    minWidth: 100,
  },
  {
    attributeName: 'vehicle_seats',
    title: 'Seats',
    displayPriority: 1,
    minWidth: 80,
  },
  {
    attributeName: 'vehicle_transmission',
    title: 'Transmission',
    displayPriority: 1,
    minWidth: 80,
    transformValue: transmission => sentenceCase(transmission),
  },
];

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Brands',
      attributeName: 'vehicle_brand',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Models',
      attributeName: 'vehicle_model',
    },
  },
];
